
<script>


    export let data;
    export let kedai_murah;
    export let metric;
    export let peringkat;
    export let width=null;
    export let dbg=false;

    import { page } from '$app/stores';

    import * as d3 from 'd3';
    import d3comparator from "$lib/d3comparator.js";

    const f1 = d3.format('.1f');


    let containerWidth;
    let items = [];

    // $:  console.log('containerWidth',containerWidth)
    // $:  if (containerWidth) {

    //         let barW = 150;
    //         let itemsPerRow = Math.floor(containerWidth/barW);
    //         let numRows = ['sm'].includes(size) ? 5 : 3;
    //         // let totalItems = d3.min([20, numRows * itemsPerRow ]);
    //         let totalItems = numRows * itemsPerRow;

    //         items = kedai_murah.slice(0, totalItems)

    //     }


    let renderChain = false;
    let renderKedai = true;


    $: dbg&&console.log('kedai_murah', kedai_murah);
    $: dbg&&console.log( 'items group', d3.groups( kedai_murah, d => d.lookup_premise.chain || d.lookup_premise.premise));

    // $: items = kedai_murah.slice(0, 12)
    $: items = kedai_murah

    $: chain = d3.groups(kedai_murah, d => d.lookup_premise.chain || d.lookup_premise.premise)
                .map(d=>{

                    return {
                        key         : d[0],
                        logo        : d[1].find(d=>d?.lookup_premise?.logo)?.lookup_premise.logo,
                        item_count  : d3.mean(d[1], d=>d.item_count),
                        diff        : d3.mean(d[1], d=>d.diff),
                        values      : d[1],
                        url         : d[1].length==1
                                        ? d[1][0].url
                                        : $page.params.negeri
                                            ? urlChainNegeri(d[0], data.negeri, data.stateid)
                                            : urlChain(d[0])
                    }

                })
                .sort(d3comparator().order(d3.ascending, d=>d.diff))



    import {
        urlDebug,
        urlKedai,
        urlChain,
        urlChainNegeri,
        shortenWord
    } from "$lib/components/Utilities.svelte";

    import {
        PremiseCard,
        PremiseHargaRendah
    } from "$lib/components/Cards";

    import * as Card from "$lib/components/ui/card";


</script>


<!-- <div class="pre">
    <div>{size}</div>
    <div>{containerWidth}px</div>
</div> -->


{#if renderChain}


    <div class="flex justify-center items-stretch flex-wrap gap-1 p-0 m-0">
        {#each chain as d, i}


            {#if d.values.length==1}

                <a
                    href={ d.url }
                    class="
                        group
                        flex-1
                        min-w-[140px] max-w-[150px]
                        min-h-[180px]
                        rounded-tl-3xl
                        rounded-tr-none
                        rounded-bl-none
                        rounded-br-none
                        bg-[#427FED]
                        hover:bg-[#532fa8]

                        relative
                    "
                >


                    {#each d.values as d}


                        <div class="
                            group-hover:bg-[#532fa8]
                            text-white
                            py-1
                            text-center

                        ">
                            <PremiseCard {d} />
                        </div>

                        <address class="text-white text-xs text-center ">
                                {#if data?.districtid}
                                    {@html d?.lookup_premise?.address||''}
                                {:else if data?.stateid}
                                    {@html d?.lookup_premise?.district||''}
                                {:else}
                                    {@html d?.lookup_premise?.district||''}, {@html d?.lookup_premise?.state||''}
                                {/if}
                        </address>


                        <!-- <div class="
                            rounded-tl-3xl
                            pl-3 pr-1
                            py-3
                            leading-3 text-left
                            text-white
                            mb-4
                        "
                        >
                            <PremiseHargaRendah {d} {metric} {peringkat}/>
                        </div> -->



                        <div class="
                            absolute
                            bottom-0
                            left-0
                            w-full
                            text-center
                            pb-2
                            pt-2

                            text-xs
                            text-white
                            leading-3
                        ">
                            <!-- <address class="store_address leading-1">
                                {#if data?.districtid}
                                    {@html d?.lookup_premise?.address||''}
                                {:else if data?.stateid}
                                    {@html d?.lookup_premise?.district||''}
                                {:else}
                                    {@html d?.lookup_premise?.district||''}, {@html d?.lookup_premise?.state||''}
                                {/if}
                            </address> -->
                            <PremiseHargaRendah {d} {metric} {peringkat}/>
                        </div>

                    {/each}


                </a>


            {:else}



                <a
                    href={ d.url }
                    class="
                        group
                        flex-1
                        min-w-[140px] max-w-[150px]
                        min-h-[220px]
                        rounded-tl-3xl
                        rounded-tr-none
                        rounded-bl-none
                        rounded-br-none
                        bg-[#427FED]
                        hover:bg-[#532fa8]

                        relative
                    "
                >



                    <div class="flex items-center justify-center pt-4 pb-1 min-h-[40px]">
                        {#if d.logo}
                            <img src="https://img.manamurah.com/premise/{d.logo}.png" class="max-h-[40px] max-w-[130px]" />
                        {/if}
                    </div>

                    <div class="
                        text-white
                        py-1
                        text-center
                        font-bold
                        text-sm
                    ">
                        {d.key}
                    </div>

                    <address class="text-white text-xs text-center">
                        (<span class="text-md font-bold">
                            { d.values.length }
                        </span>
                        cawangan)
                    </address>


                    <!-- <div class="
                        pl-3 pr-1
                        py-3
                        leading-3 text-left
                        text-white
                        mb-4
                    "
                    >
                            <span class="text-xs">Purata</span>
                            <span class="text-md font-bold">{ f1(d.diff*100) }%</span>
                            <span class="text-xs">lebih rendah dari harga purata {peringkat} untuk purata</span>
                            <span class="text-md font-bold">{ Math.floor(f1(d.item_count)) }</span>
                            <span class="text-xs">barangan di </span>
                            <span class="text-md font-bold">{ d.values.length }</span>
                            <span class="text-xs">cawangan {d.key}</span>
                    </div> -->


                    <div class="
                        absolute
                        bottom-0
                        left-0
                        w-full
                        text-center
                        pb-2
                        pt-2
                        px-2

                        text-xs
                        text-white
                        leading-3
                    ">
                        <!-- <span class="text-md font-bold">
                            { d.values.length }
                        </span>
                        cawangan -->

                            <span class="text-xs">Purata</span>
                            <span class="text-md font-bold">{ f1(d.diff*100) }%</span>
                            <span class="text-xs">lebih rendah dari harga purata {peringkat} untuk purata</span>
                            <span class="text-md font-bold">{ Math.floor(f1(d.item_count)) }</span>
                            <span class="text-xs">barangan di </span>
                            <span class="text-md font-bold">{ d.values.length }</span>
                            <span class="text-xs">cawangan {d.key}</span>
                    </div>

                </a>

            {/if}



        {/each}

    </div>


{/if}







{#if renderKedai}



    <div class="flex justify-center items-stretch flex-wrap gap-1 p-0 m-0" bind:offsetWidth={containerWidth}>


        {#each items.slice(0,3*Math.floor((width/150))) as d, i}

            <a
                href={ urlDebug(dbg, d.url || urlKedai(shortenWord(d.lookup_premise.premise), d.lookup_premise.premise_code)) }
                class="
                    group
                    flex-1
                    min-w-[140px] max-w-[150px]
                    rounded-tl-3xl
                    rounded-tr-none
                    rounded-bl-none
                    rounded-br-none
                    bg-[#427FED]
                    hover:bg-[#532fa8]

                    relative
                "
            >


                    <div class="
                        rounded-tl-3xl
                        pl-3 pr-1
                        py-3
                        leading-3 text-left
                        bg-[#532fa8]
                        text-white
                    "
                    >
                        <PremiseHargaRendah {d} {metric} {peringkat}/>
                    </div>

                    <div class="
                        bg-[#427FED]
                        group-hover:bg-[#532fa8]
                        text-white
                        py-1
                        text-center

                        mb-10
                    ">
                        <PremiseCard {d} />
                    </div>

                    <div class="
                        absolute
                        bottom-0
                        left-0
                        w-full
                        text-center
                        pb-2
                        pt-2

                        text-xs
                        text-white
                        leading-3
                        bg-[#0000ff40]
                    ">
                        <address class="store_address leading-1">
                            {#if data?.districtid}
                                {@html d?.lookup_premise?.address||''}
                            {:else if data?.stateid}
                                {@html d?.lookup_premise?.district||''}
                            {:else}
                                {@html d?.lookup_premise?.district||''}, {@html d?.lookup_premise?.state||''}
                            {/if}
                        </address>
                    </div>


                    {#if dbg}
                        <div class="text-xs text-[black] text-right overflow-hidden">
                            {d.url}
                        </div>
                    {/if}

            </a>



        {/each}
    </div>


{/if}
