<script>


    // export let data;
    export let d;

    import {
        shortenWord,
        labelPremise
    } from "$lib/components/Utilities.svelte";


    import * as Card from "$lib/components/ui/card";

</script>

{#if d.lookup_premise}

    <Card.Content class="
            store mx-0 p-1 flex justify-center h-[40px]
        ">
        {#if d.lookup_premise.logo}
            {#if d.lookup_premise.logo.match(/KIV/)}
                <img alt="{d.lookup_premise.premise}"
                    class="store_logo"
                    style="height:40px; width:40px; margin:0;"
                    src="https://img.manamurah.com/odot.gif"/>
            {:else}
                <img alt="{d.lookup_premise.premise}"
                    class="store_logo"
                    style="max-height:40px; margin:0;"
                    src="https://img.manamurah.com/premise/{d.lookup_premise.logo}.png"/>
            {/if}
        {/if}
    </Card.Content>
    <Card.Header class="px-2 py-0 my-0">
        <Card.Title tag="h6" class="text-white">
            <div class="store_name text-sm leading-4">
                {@html labelPremise(shortenWord(d.lookup_premise.premise))}
            </div>
        </Card.Title>
        <!-- <Card.Description class="text-xs  text-white">
            <address class="store_address">
                {#if data?.districtid}
                    {@html d?.lookup_premise?.address||''}
                {:else if data?.stateid}
                    {@html d?.lookup_premise?.district||''}
                {:else}
                    {@html d?.lookup_premise?.district||''}, {@html d?.lookup_premise?.state||''}
                {/if}
            </address>
        </Card.Description> -->
    </Card.Header>

{/if}
