<script context="module">

    export let Config = {
        colors: {
            blue: '#427FED',
            red:'#d72222',
            green:'#3b9037',
            purple:'#532fa8',
            grey: '#cccccc',
        }
    }

</script>
