
<script>

    export let d;
    export let metric;
    // export let color;
    // export let scale;
    export let peringkat;

    import * as d3 from 'd3';
    import chroma from 'chroma-js';
    import numeral from 'numeral';

    const f1 = d3.format('.1f');

    import { Config } from "$lib/Config.svelte";
    // const _color = chroma(Config.colors.blue).darken().hex();
    // const _color = chroma('#eab23a').hex();
    // const _color ='#6dc164'
    const _color = d[metric] < 0
                    ? Config.colors.purple
                    : Config.colors.red;

</script>




        <!-- background:{color(scale(d[metric]))};
        color:{ chroma(color(scale(d[metric]))).luminance()>.3?'#000':'#fff'} -->

<div>
    {#if +f1(d[metric] *100) == 0}


        <span class="text-xs">
            Harga purata
            {peringkat}
        </span>
        <div>
            <span class="text-xs">
                untuk
            </span>
            <span class="font-semibold text-md">{d.item_count}</span>
            <span class="text-xs">
                barangan
            </span>

        </div>
        <div>&nbsp;</div>

    {:else}

        <span class="text-md font-semibold">
            {#if d[metric] < 0}
                { f1(d[metric] *100) }%
            {:else if +f1(d[metric]*100) == 0}
                { f1(d[metric]*100) }%
            {:else if d[metric] > 0}
                { numeral(d[metric]*100).format('+0.0') }%
            {/if}
        </span>

        <span class="text-xs">
            {#if d[metric] < 0}
                lebih rendah
            {:else if d[metric] > 0}
                lebih tinggi
            {/if}
            dari harga purata
            {peringkat}
            untuk
        </span>
        <span class="font-semibold text-md">{d.item_count}</span>
        <span class="text-xs">
            barangan
        </span>
    {/if}

</div>


